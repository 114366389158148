import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Maintenances = ({ location }) => {
  return (
    <Layout lang="zh-CN">
      <Seo
        pagetitle="故障和维修信息"
        pagepath={location.pathname}
        pagedesc="故障和维修信息 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
      />
      <Breadcrumb title="故障和维修信息" />
      <main className="information">
        <section className="information__section l-container--m">
          <h1 className="information__title">目前的残疾信息</h1>
          <ul className="maintenance__list">
            <li className="maintenance__item">
              <p className="maintenance__title">通知</p>
              <time className="maintenance__time">2021/2/24</time>
              <p className="maintenance__text">
                <a className="information__link" href="/">
                  关于停止创建新运动功能的通知
                </a>
              </p>
            </li>
            <li className="maintenance__item">
              <p className="maintenance__title">
                残疾
                <span className="maintenance__badge badge--secondary">
                  恢复
                </span>
              </p>
              <time className="maintenance__time">2020/12/19</time>
              <p className="maintenance__text">
                <a className="information__link" href="/">
                  由TwitterAPI引起的失败通知
                </a>
              </p>
            </li>
          </ul>
        </section>
      </main>
    </Layout>
  )
}

export default Maintenances
